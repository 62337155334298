import jwtDecode from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getMe, getMyRoles } from '@sweetspot/shared/data-access/api-platform'
import {
  emptyPartnershipList,
  loginFailure,
  loginSuccess,
  loginClearError,
  logoutSuccess,
  updateRole,
  loginRequest,
} from '@sweetspot/apps/partner-portal/store/actions'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { getFirstMatchingRole } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import {
  clubUserLogin,
  loginToClub as loginToClubService,
} from '@sweetspot/sweetspot-js/features/auth/services/api-platform'
import { persistor } from '@sweetspot/apps/partner-portal/store/configureStore'
import CryptoJS from 'crypto-js'
import { setLocalStorage } from '@sweetspot/shared/util/local-storage'
import {
  API_PLATFORM_TOKEN_SALTS,
  API_PLATFORM_TOKEN_STORAGE_KEYS,
  clearAllStoredTokens,
  storeAuthToken,
  storeRefreshToken,
} from '@sweetspot/shared/data-access/api-platform-auth'

const useAuthService = () => {
  const { t, i18n } = useTranslation()
  const { isFetching, currentUser, isAuthenticated, errorMessage } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()

  /**
   * Attempts to login
   *
   * @param {Object} payload
   * @param {string} payload.email
   * @param {string} payload.password
   * @returns {promise}
   */
  const attemptLogin = async (payload) => {
    persistor.flush()

    clearAuthError()
    dispatch(loginRequest())

    const [res, err] = await to(clubUserLogin(payload))
    if (err) {
      let message = t('sentences.invalidLoginDetails')
      dispatch(loginFailure(message))
      setTimeout(clearAuthError, 7500)
      return
    }
    // NOTE - WARNING - save password in different way to use
    const encrytedPassword = CryptoJS.AES.encrypt(
      payload.password,
      API_PLATFORM_TOKEN_SALTS.AUTH_TOKEN_SALT
    ).toString()
    setLocalStorage('encrypted_key', encrytedPassword)
    const { token, refresh_token: refreshToken } = res

    storeAuthToken(token)
    storeRefreshToken(refreshToken)

    const [resMe] = await to(getMe())
    const [resMyRoles] = await to(getMyRoles())

    const decodedToken = jwtDecode(token)
    const roles = decodedToken.roles_current_club

    resMe.role = getFirstMatchingRole(roles).value
    resMe.roles = resMyRoles

    const language = resMe.language === 'se' ? 'sv' : 'en'
    i18n.changeLanguage(language, (err) => {
      if (err) return console.log('something went wrong loading', err)
    })

    dispatch(loginSuccess(resMe))
    return
  }

  const loginToClub = async (clubUuid) => {
    const [res, err] = await to(loginToClubService(clubUuid))

    if (err || !res) {
      return [false, err]
    }

    const { token: accessToken } = res
    storeAuthToken(accessToken)

    const decodedToken = jwtDecode(accessToken)
    const roles = decodedToken.roles_current_club

    const role = getFirstMatchingRole(roles).value

    dispatch(updateRole(role))

    return [true, null]
  }

  /**
   * Attemps to logout
   *
   * @returns {promise}
   */
  const attemptLogout = async () => {
    clearAllStoredTokens()
    dispatch(emptyPartnershipList())
    dispatch(logoutSuccess())
    persistor.flush()
    return
  }

  /**
   * Clears auth error from state
   *
   * @returns {void}
   */
  const clearAuthError = () => {
    dispatch(loginClearError())
  }

  return {
    attemptLogin,
    attemptLogout,
    loginToClub,
    clearAuthError,
    isAuthenticated,
    isLoggingIn: isFetching,
    currentUser,
    errorMessage,
  }
}

export default useAuthService
